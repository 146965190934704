<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :description="searchOptions[0].label"
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="openModalCustomer()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm mới
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          @click="$refs.file.click()"
        >
          <v-icon left>mdi-file-upload-outline</v-icon> Nhập file
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          :loading="customerStatusRequest.value === 'loading-exportReportFile'"
          @click="$emit('exportReportFile')"
        >
          <v-icon left>mdi-file-download-outline</v-icon> Xuất file
        </v-btn>
        <input
          type="file"
          ref="file"
          hidden
          accept=".xlsx, .xls"
          @change="uploadExcelFile()"
        />
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [
        {
          type: "search",
          label: "Theo tên, SĐT, email, mã khách hàng"
        }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["CUSTOMER/paginationInfo"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    }
  },
  methods: {
    async openModalCustomer() {
      await this.$store.dispatch("CUSTOMER/resetCustomer");
      this.$modal.show({ name: "modal-customer" });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();
      const routeQuery = this.$route.query;

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("THIRD_STORE/importExcel", {
        formData: formData,
        routeQuery: {
          filters: {
            supplier_id:
              typeof routeQuery.supplier_id === "string"
                ? [parseInt(routeQuery.supplier_id)]
                : typeof routeQuery.supplier_id === "object"
                ? routeQuery.supplier_id.map(item => parseInt(item))
                : null,
            stock_status: routeQuery.store_status
              ? parseInt(routeQuery.store_status)
              : null,
            fromPrice: parseInt(routeQuery.price_from),
            toPrice: parseInt(routeQuery.to)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Check request status
      if (this.thirdStoreStatusRequest.value === "success-importExcel") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      }
      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
