<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Tất cả khách hàng</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Tình trạng giao dịch</div>
        <v-radio-group
          v-model="selectedTransactionStatus"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Đã mua hàng" value="1"></v-radio>
          <v-radio label="Chưa mua hàng" value="0"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tổng tiền mua</div>
        <tp-input-price-filter
          :items="buyTotalValueList"
          :value="buyTotalFilter"
          @change="updateBuyTotalFilter($event)"
        ></tp-input-price-filter>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tổng tiền trả</div>
        <tp-input-price-filter
          :items="returnTotalValueList"
          :value="returnTotalFilter"
          @change="updateReturnTotalFilter($event)"
        ></tp-input-price-filter>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Công nợ</div>
        <tp-input-price-filter
          :items="debtValueList"
          :value="debtFilter"
          @change="updateDebtFilter($event)"
        ></tp-input-price-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    buyTotalFilter: {
      type: Object
    },
    debtFilter: {
      type: Object
    },
    returnTotalFilter: {
      type: Object
    },
    transactionStatusFilter: {
      type: String
    }
  },
  data() {
    return {
      buyTotalValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        {
          id: 4,
          text: "10 - 30 triệu",
          value: { from: 10000000, to: 30000000 }
        },
        {
          id: 4,
          text: "30 - 50 triệu",
          value: { from: 30000000, to: 50000000 }
        },
        { id: 4, text: "Trên 100 triệu", value: { from: 100000000, to: null } }
      ],
      debtValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        { id: 4, text: "Nhỏ hơn 0", value: { from: null, to: -0.000001 } }
      ],
      returnTotalValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } }
      ]
    };
  },
  computed: {
    selectedTransactionStatus: {
      get() {
        return this.transactionStatusFilter;
      },
      set(val) {
        this.$emit("updateTransactionStatusFilter", val);
      }
    }
  },
  methods: {
    updateBuyTotalFilter(val) {
      this.$emit("updateBuyTotalFilter", val);
    },
    updateDebtFilter(val) {
      this.$emit("updateDebtFilter", val);
    },
    updateReturnTotalFilter(val) {
      this.$emit("updateReturnTotalFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
