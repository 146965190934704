<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="customerStatusRequest.value === 'loading-getCustomers'"
    loading-text="Đang tải dữ liệu"
    :items="customers"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.name`]="{ item }">
      {{ !item.guest ? item.name : item.alias ? item.alias : "-" }}
    </template>

    <template v-slot:[`item.phone`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.phone)"
          >
            {{ item.phone }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.tong_mua`]="{ item }">
      {{ item.tong_mua | formatCurrency }}
    </template>

    <template v-slot:[`item.tong_tra`]="{ item }">
      {{ item.tong_tra | formatCurrency }}
    </template>

    <template v-slot:[`item.cong_no`]="{ item }">
      {{ item.cong_no | formatCurrency }}
    </template>
  </v-data-table>
</template>

<script>
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  data() {
    return {
      headers: [
        {
          text: "Mã KH",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên khách hàng/Tên định danh",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Số điện thoại",
          align: "start",
          sortable: false,
          value: "phone"
        },
        {
          text: "Tổng tiền mua",
          align: "start",
          sortable: false,
          value: "tong_mua"
        },
        {
          text: "Tổng tiền trả",
          align: "start",
          sortable: false,
          value: "tong_tra"
        },
        {
          text: "Công nợ",
          align: "start",
          sortable: false,
          value: "cong_no"
        },
        {
          text: "Giao dịch cuối",
          align: "start",
          sortable: false,
          value: "giao_dich_cuoi"
        }
      ]
    };
  },
  computed: {
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },
    customerStatusRequest() {
      return this.$store.getters["CUSTOMER/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfCustomer(val) {
      const arr = this.customers.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", item.id);

      this.$modal.show({
        name: "modal-customer",
        payload: {
          index: this.getIndexOfCustomer(item.id)
        }
      });

      this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: item.id,
        type: BANK_ACCOUNT_OBJECT_TYPE.USER
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
