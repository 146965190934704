<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :buy-total-filter="buyTotalFilter"
        :debt-filter="debtFilter"
        :return-total-filter="returnTotalFilter"
        :transaction-status-filter="transactionStatusFilter"
        @updateBuyTotalFilter="updateBuyTotalFilter($event)"
        @updateDebtFilter="updateDebtFilter($event)"
        @updateReturnTotalFilter="updateReturnTotalFilter($event)"
        @updateTransactionStatusFilter="updateTransactionStatusFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { exportExcelFile } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      buyTotalFilter: {
        from: null,
        to: null
      },
      currentPage: 1,
      debtFilter: {
        from: null,
        to: null
      },
      itemPerPage: 50,
      returnTotalFilter: {
        from: null,
        to: null
      },
      searchKey: null,
      transactionStatusFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getCustomers(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getCustomers(route.query);
  },
  methods: {
    async exportReportFile() {
      await exportExcelFile(this, {
        storeName: "CUSTOMER",
        payload: {
          filter: {
            tradeStatus:
              this.transactionStatusFilter &&
              this.transactionStatusFilter !== "all"
                ? parseInt(this.transactionStatusFilter)
                : null,
            fromBuy: this.buyTotalFilter.from,
            toBuy: this.buyTotalFilter.to,
            fromReturn: this.returnTotalFilter.from,
            toReturn: this.returnTotalFilter.to,
            fromBalance: this.debtFilter.from,
            toBalance: this.debtFilter.to
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        },
        fileName: "danh-sach-khach-hang-"
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "partner-and-member_customers",
        query: {
          trans_status:
            this.transactionStatusFilter &&
            this.transactionStatusFilter !== "all"
              ? this.transactionStatusFilter
              : undefined,
          buy_from:
            this.buyTotalFilter.from !== null
              ? this.buyTotalFilter.from
              : undefined,
          buy_to:
            this.buyTotalFilter.to !== null
              ? this.buyTotalFilter.to
              : undefined,
          return_from:
            this.returnTotalFilter.from !== null
              ? this.returnTotalFilter.from
              : undefined,
          return_to:
            this.returnTotalFilter.to !== null
              ? this.returnTotalFilter.to
              : undefined,
          debt_from:
            this.debtFilter.from !== null ? this.debtFilter.from : undefined,
          debt_to: this.debtFilter.to !== null ? this.debtFilter.to : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getCustomers(query) {
      if (query.page) {
        // Set buy total filter
        this.buyTotalFilter = {
          from:
            query.buy_from !== undefined ? parseFloat(query.buy_from) : null,
          to: query.buy_to !== undefined ? parseFloat(query.buy_to) : null
        };
        // Set debt filter
        this.debtFilter = {
          from:
            query.debt_from !== undefined ? parseFloat(query.debt_from) : null,
          to: query.debt_to !== undefined ? parseFloat(query.debt_to) : null
        };
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set return total filter
        this.returnTotalFilter = {
          from:
            query.return_from !== undefined
              ? parseFloat(query.return_from)
              : null,
          to: query.return_to !== undefined ? parseFloat(query.return_to) : null
        };
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered status
        this.transactionStatusFilter = query.trans_status
          ? query.trans_status
          : "all";

        // Get products
        await this.$store.dispatch("CUSTOMER/getCustomers", {
          filter: {
            tradeStatus:
              this.transactionStatusFilter &&
              this.transactionStatusFilter !== "all"
                ? parseInt(this.transactionStatusFilter)
                : null,
            fromBuy: this.buyTotalFilter.from,
            toBuy: this.buyTotalFilter.to,
            fromReturn: this.returnTotalFilter.from,
            toReturn: this.returnTotalFilter.to,
            fromBalance: this.debtFilter.from,
            toBalance: this.debtFilter.to
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateBuyTotalFilter(val) {
      this.buyTotalFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDebtFilter(val) {
      this.debtFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateReturnTotalFilter(val) {
      this.returnTotalFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTransactionStatusFilter(val) {
      this.transactionStatusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
